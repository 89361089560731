<template>
  <div class="banner">
    <!-- <el-image class="logo" :src="require('../assets/img/123.jpg')">
     
     </el-image> -->
    <div class="logo">
      <div class="banner-font">
        <div class="font-english">SCIENCE MOVEMENT LEADER</div>
        <div class="font-chinese">科学运动引领者</div>
      </div>
    </div>
    <div class="bg"  :id="'one'+$route.params.id ">
      <div class="bg-left">使命与愿景</div>
      <div class="bg-right">
        <div class="bg-right-one">
          树立运动榜样 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;引领全民健康
        </div>
        <div class="bg-right-two">
          Corporate
          &nbsp;Mission&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业使命
        </div>
        <div class="bg-right-one">树立10万+运动榜样，服务1亿人，</div>
        <div class="bg-right-one">爱运动，会运动，从而收获健康人生。</div>
        <div class="bg-right-two">
          Corporate
          &nbsp;Vision&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业愿景
        </div>
      </div>
    </div>
    <div class="Culture" :id="'two'+$route.params.id ">
      <div class="Culture-content">
        <div class="Culture-content-left">
          <div class="Culture-content-left-title">五G文化</div>
          <div class="Culture-content-left-one">运动观</div>
          <div class="Culture-content-left-two">专业引领 科学运动</div>
          <div class="Culture-content-left-one">运动官</div>
          <div class="Culture-content-left-two">
            经过平台验证具有专业性的体育从业者
          </div>
          <div class="Culture-content-left-one">运动管</div>
          <div class="Culture-content-left-two">
            体育运动协同服务SAAS平台，区块链技术赋能大数据管理
          </div>
          <div class="Culture-content-left-one">运动关</div>
          <div class="Culture-content-left-two">
            优化与整合运动相关联的上游体育服务与产品供应商
          </div>
          <div class="Culture-content-left-one">运动冠</div>
          <div class="Culture-content-left-two">
            助推运动榜样的影响力建设，打造10万+运动榜样和以运动<br />榜样为中心的个性化体育社群，覆盖终端1-3亿人
          </div>
        </div>
        <div class="Culture-content-right">
          <video
            class="video"
            src="../assets/video/jianji.webm"
            autoplay
            muted
            loop
            controls
          ></video>
        </div>
      </div>
    </div>
    <div class="route" :id="'Three'+$route.params.id ">
      <div class="route-content">
        <div class="route-content-title">五年路径</div>
        <div class="route-content-top">
          <div class="route-content-top-one">
            <div class="route-content-top-year">
              <span>2020</span>
              <span class="qizi"></span>
            </div>
            <div class="route-content-top-info">渠道开发资源集中整合</div>
            <div class="route-content-top-english">
              CENTRALIZED INTEGRATION OF<br />CHANNEL DEVELOPMENT RESOURCES
            </div>
          </div>
          <div class="route-content-top-one">
            <div class="route-content-top-year">
              <span>2022</span>
              <span class="xiaoren"></span>
            </div>
            <div class="route-content-top-info">平台建设：实现品质标准化</div>
            <div class="route-content-top-english">
              PLATFORM CONSTRUCTION:<br />REALIZE QUALITY STANDARDIZATION
            </div>
          </div>
          <div class="route-content-top-one">
            <div class="route-content-top-year">
              <span>2024</span>
              <span></span>
            </div>
            <div class="route-content-top-info">
              协同平台+整合产业链：实现数据智能+服务集成
            </div>
            <div class="route-content-top-english">
              COLLABORATIVE PLATFORM + INTEGRATED INDUSTRY<br />CHAIN:REALIZE
              DATA INTELLIGENCE + SERVICE INTEGRATION
            </div>
          </div>
        </div>
        <div
          class="route-content-top"
          style="margin-top: 0.4rem; border-top: 1px dashed #111"
        >
          <div style="width: 1.5rem"></div>
          <div class="route-content-top-one">
            <div class="route-content-top-year">
              <span>2021</span>
              <span class="qizi"></span>
            </div>
            <div class="route-content-top-info">产品输出：运动官SAAS平台</div>
            <div class="route-content-top-english">
              PRODUCT OUTPUT:<br />SPORTS OFFICER SAAS PLATFORM
            </div>
          </div>
          <div style="width: 0.75rem"></div>
          <div class="route-content-top-one">
            <div class="route-content-top-year">
              <span>2023</span>
              <span></span>
            </div>
            <div class="route-content-top-info">
              深挖用户特征：实现服务定制化
            </div>
            <div class="route-content-top-english">
              DIG DEEP INTO USER CHARACTERISTICS:<br />REALIZE SERVICE
              CUSTOMIZATION
            </div>
          </div>
          <div class="route-content-top-one"></div>
        </div>
      </div>
    </div>
    <div class="bigText" :id="'Five'+$route.params.id ">
      <div class="bigText-content">
         <div class="bigText-content-title">大事记</div>
         <div class="bigText-content-details">
          <div  class="bigText-content-details-item" v-for="(item,index) in bigTextArr" :key="index" :style="{background: `url(${item.imgUrl})`}" >
              <div class="bigText-content-details-shadow" :style="{backgroundColor:index%2==0?'rgba(2, 113, 164, 0.8)':'rgba(0, 0, 0, 0.8)'}"  :class="isClose&&activeShow==index?'flip-front':'flip-back'" @click="handleShadow(index)">
                 <div  class="Adaptation" :class="isClose&&activeShow==index?'flip-front':'flip-back'">
                 <div class="shadow-date">{{item.date}}</div>
                 <div class="shadow-company">{{item.company}}</div>
                <div class="shadow-mark">{{item.mark}}</div>
                  </div>
              </div>
               <div class="bigText-content-details-intro"  v-if="isClose&&activeShow==index"> 
                 <p :title="item.intro">
                  {{item.intro}}
                 </p>
                 <i  class="el-icon-d-arrow-left bigText-content-details-intro-icon" @click="handleClose" ></i>
               </div>
          </div>
          <div class="bigText-content-details-item-right">

          </div>

         </div>
         <div class="bigText-content-details-next">
              <div class="bigText-content-details-next-left">

              </div>
              <div class="bigText-content-details-item" v-for="(item,index) in bigTexNexttArr" :key="index" :style="{background: `url(${item.imgUrl})`}">
              <div class="bigText-content-details-shadow" :style="{backgroundColor:index%2==0?'rgba(2, 113, 164, 0.8)':'rgba(0, 0, 0, 0.8)'}"  :class="isClose&&activeShow==index+4?'flip-front':'flip-back'" @click="handleShadow(index+4)">
                  <div  class="Adaptation" :class="isClose&&activeShow==index+4?'flip-front':'flip-back'">
                 <div class="shadow-date">{{item.date}}</div>
                 <div class="shadow-company">{{item.company}}</div>
                <div class="shadow-mark">{{item.mark}}</div>
                  </div>
              </div>
               <div class="bigText-content-details-intro"  v-if="isClose&&activeShow==index+4"> 
                 <p :title="item.intro">
                  {{item.intro}}
                 </p>
                 <i  class="el-icon-d-arrow-left bigText-content-details-intro-icon" @click="handleClose" ></i>
               </div>
               </div>
               <div class="bigText-content-details-next-right">
                  <div class="bigText-content-details-next-right-content">
                    <div v-for="(item,index) in bigTextLastArr" :key="index" :style="{background: `url(${item.imgUrl})`}">

                    </div>
                    
                  </div>
                  <div class="bigText-content-details-next-right-shadow"   :class="isClose&&activeShow==6?'flip-front':'flip-back'" @click="handleShadow(6)">
                       <div  class="Adaptation" :class="isClose&&activeShow==6?'flip-front':'flip-back'">
                        <div class="shadow-date">2022/12 <i class="el-icon-trophy" style="font-size:0.2rem"></i></div>
                        <div class="shadow-company">青岛市体育局正式授予“青岛周司健康科技有限公司”</div>
                        <div class="shadow-mark">「 青岛市数字体育服务试点单位 」</div>
                       </div>
                  </div>
                  <div class="bigText-content-details-intro"  v-if="isClose&&activeShow==6"> 
                 <p title="为进一步扩大青岛市体育消费规模  ，营造安全放心的体育消费环境 ，争创国家体育消费示范城市，根据《青岛市人民政府办公厅关于促进全民健身和体育消费推动体育产业高质量发展的实施意见》（青政办字〔 2021 〕1号 ） 第八条“探索建立健身消费卡预付资金托管模式,维护消费者权益,增强消费信心,营造放心、便捷消费环境。”青岛市体育局通过扶持技术、理念先进的企业以促进体育产业的发展。青岛周司健康科技有限公司开发的“运动官数字体育SAAS服务系统” 于2022年8月份上线运行，平台不仅能够帮助体育服务企业快速形成成熟的营销网络、降低推广成本，并且在加强体育类预付消费资金监管方面，与央行支付牌照第三方平台、国有银行成立了三方共管的体育资金安全监管账户，能够有效保障消费者权益 。2022年12月 ，青岛市体育局授予青岛周司健康科技有限公司“青岛市数字体育服务试点单位”，并举行了授牌仪式。">
                  为进一步扩大青岛市体育消费规模  ，营造安全放心的体育消费环境 ，争创国家体育消费示范城市，根据《青岛市人民政府办公厅关于促进全民健身和体育消费推动体育产业高质量发展的实施意见》（青政办字〔 2021 〕1号 ） 第八条“探索建立健身消费卡预付资金托管模式,维护消费者权益,增强消费信心,营造放心、便捷消费环境。”青岛市体育局通过扶持技术、理念先进的企业以促进体育产业的发展。青岛周司健康科技有限公司开发的“运动官数字体育SAAS服务系统” 于2022年8月份上线运行，平台不仅能够帮助体育服务企业快速形成成熟的营销网络、降低推广成本，并且在加强体育类预付消费资金监管方面，与央行支付牌照第三方平台、国有银行成立了三方共管的体育资金安全监管账户，能够有效保障消费者权益 。2022年12月 ，青岛市体育局授予青岛周司健康科技有限公司“青岛市数字体育服务试点单位”，并举行了授牌仪式。
                 </p>
                 <i  class="el-icon-d-arrow-left bigText-content-details-intro-icon" @click="handleClose" ></i>
               </div>
                </div>
         </div>
      </div>
    </div>
    <div class="mine" :id="'four'+$route.params.id ">
      <div class="mine-content">
        <div class="mine-content-title">关于我们</div>
        <div class="mine-content-details">
          青岛周司健康科技有限公司成立于2018年10月，企业拥有国家级运动员团队及独立的区块链技术团队；业务将涵盖运动教育、运动康复与预防、运动俱乐部、运动文旅、运动金融、智能硬件、智慧运动服务中心等与运动相关的服务板块；线上采用区块链的技术，用诚信链接每个环节，形成愿景共同体、服务共同体和利益共同体的运动服务生态链盟。
        </div>
        <div class="mine-content-details">
          目前核心产品为“运动官智慧体育SaaS云平台”——通过移动互联网工具，为运动供应商、专业运动人才、运动爱好者，搭建了一个可信的、高效的体育运动协同服务SaaS平台，是坚持以真人、真服务、真交易为平台战略的运动服务生态系统。
        </div>
        <div class="mine-content-img">
          <el-image
            :src="require('../assets/img/mine-content-img-1.jpg')"
            fit="cover"
          ></el-image>
          <el-image
            :src="require('../assets/img/6H5A41581.jpg')"
            fit="cover"
          ></el-image>
          <el-image
            :src="require('../assets/img/6H5A5637.jpg')"
            fit="cover"
          ></el-image>
        </div>
      </div>
    </div>
    <div class="mine-content-adress">
      <el-image
        :src="require('../assets/img/adress.jpg')"
        fit="cover"
      ></el-image>
    </div>
    <div class="contact">
      <div class="contact-left">
        <div class="contact-content-left">
          <span class="contact-title">联系方式<br /></span>
          <span class="contact-content-top"
            >联系地址：中国山东省青岛市崂山区银川东路3号国信体育中心M区负一层<br
          /></span>

          <span>
            客服热线：400-8571-778 （ 工作时间： 8:45-17:45 周一至周五 ）<br />
          </span>

          <span>意见反馈：请通过 support@sportsofficer.xin 联系我们 <br /></span>
        </div>
      </div>
      <div class="contact-right">
        <div class="contact-content-right">
          <span class="contact-title">业务合作<br /></span>
          <span class="contact-content-top"
            >商务合作：market@sportsofficer.xin<br
          /></span>

          <span> 媒体合作：market@sportsofficer.xin<br /> </span>
          <span>广告合作：market@sportsofficer.xin<br /></span>
        </div>
      </div>
    </div>
    <div class="download">
      <div class="download-content">
        <div class="download-content-left">
          <div class="download-content-left-info">
            <div class="iconlogo">
              <el-image
                class="iconlogoimg"
                :src="require('../assets/img/logo_white.png')"
              ></el-image>
              <span class="iconlogotext">运动官</span>
            </div>
            <div class="download-content-left-introduce">
              <span>运动生活&nbsp;&nbsp;&nbsp;快乐有我<br /></span>
              <span>科学运动引领者<br /></span>
            </div>
            <div class="download-content-left-mode">
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-center"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/sportQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-apple"></div>
              </el-tooltip>
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-bottom"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/sportQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-android"></div>
              </el-tooltip>
            </div>
            <div class="iconlogo" style="margin-top: 0.8rem">
              <el-image
                class="iconlogoimg"
                :src="require('../assets/img/logo_black.png')"
              ></el-image>
              <span class="iconlogotext">运动官商家版</span>
            </div>
            <div class="download-content-left-introduce">
              <span>垂直体育分销系统<br /></span>
              <span>科学运动引领者<br /></span>
            </div>
            <div class="download-content-left-mode">
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-center"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/businessQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-apple"></div>
              </el-tooltip>
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-bottom"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/businessQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-android"></div>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="download-content-right">
          <div class="download-content-right-mode"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/api/mixin'
// import OverflowTooltip from "./OverflowTooltip";
export default {
     mixins:[mixin],
  //      components: {
  //   OverflowTooltip,
  // },
     data(){
      return{
        isClose:false,
        activeShow:'',
        bigTextArr:[
          {
            imgUrl:require('../assets/img/u334.jpg'),
            date:'2018/10',
            company:'青岛周司健康科技有限公司',
            mark:'荣耀成立',
            intro:'青岛周司健康科技有限公司成立于2018年10月，企业拥有国家级运动员团队及独立的区块链技术团队；业务涵盖数字体育服务软件、智能硬件、体育俱乐部、运动康复与预防、体育保险、智慧体育服务中心等与运动相关的服务板块；线上采用区块链技术，用诚信链接每个环节，形成愿景共同体、服务共同体和利益共同体的体育服务生态链盟。'
          },
          {
            imgUrl:require('../assets/img/u343.png'),
            date:'2020/05',
            company:'运动官云护照小程序上线',
            mark:'｜学员端｜教练端｜场馆端',
            intro:'“运动官云护照”利用区块链，让会员权益更有保障，信息更加真实。青岛周司健康科技有限公司为用户提供科学的、专业的、可信的、便捷的、个性化运动服务，运动官云护照聚集了百家运动场馆，消费者可以获得激活码，在微信小程序“运动官”进行激活 ，到合作的百家场馆进行畅玩体验  ，运动轨迹、运动数据等重要信息进行上链，线下各场馆可以验真消费者的信息，保证了消费者的信息真实性、维护了消费者的权益。用户可分享自己运动官云护照晒出运动打卡记录，带动身边更多运动参与者的加入。'
          },
          {
            imgUrl:require('../assets/img/u342.png'),
            date:'2021/03',
            company:'运动官教练端 V1.0 上线',
            mark:'Android   IOS',
            intro:'运动官教练端是提供给与运动官签约的体育机构、俱乐部及教练个人的学员管理APP，APP功能范围包括教练个人Vlog视频推广、共享场地使用、意向客户管理 、学员管理、个人名片、生成私教课/团课、安全支付、便捷结算等服务 。APP为私人运动教练提供专业的平台认证、形象包装、发布课程、学员引流、学员管理、课程预约、在线结算、在线沟通、图文互动、等全方位管理功能。 并支持体育机构 、俱乐部采用组别管理旗下教练 ，线上机构统一结算'
          },
          {
            imgUrl:require('../assets/img/u345.jpg'),
             date:'2021/04',
            company:'运动官学员端 V1.0 上线',
            mark:'Android   IOS',
            intro:'运动官是一款非常好用的健康运动管理软件，这款软件为爱好运动的小伙伴们打造了一个运动管理服务平台，在这里可以轻松的记录下你的各种日常运动信息   ，同时在这里可以为你提供一些健康运动的建议，提高你的生活质量。运动官app是一款大众运动健康管理软件，通过用户自行输入身高、体重等个人信息为用户计算BMI健康数据，并给出健康建议；运动数据实时记录工具培养用户养成科学运动健康习惯的同时，还可以累计步数换取实物奖励。'
          }

        ],
        bigTexNexttArr:[
           {
            imgUrl:require('../assets/img/u341.png'),
            date:'2022/08',
            company:'运动官商家版 V1.0 上线',
            mark:'Android   IOS',
            intro:'运动官致力为体育行业创建一种见效快，成本低的有偿分销网络。体育机构作为资源方可以将自己的课程、场地、教学视频等发布到平台，并附带推广佣金，运动官平台为体育机构提供渠道管理、营销推广、交易结算等一站式服务。推广官一键生成体育网店，丰富的体育服务资源通过微信小程序分享给周边的体育爱好者，带动全民健身。网店使用无需培训、轻松上手、简易维护，推广健身方式的同时轻松赚取佣金。'
          },
          {
            imgUrl:require('../assets/img/u350.jpg'),
             date:'2022/09',
            company:'运动官小程序 V1.0 上线',
            mark:'｜用户端｜核销端｜',
            intro:'运动官用户端小程序，运动社交生活平台，大众健身、小众运动，潮酷体验。飞盘、橄榄球、滑板、帆船、桨板、潜水、网球、滑雪、棒球、攀岩、羽毛球、射箭、滑翔伞，酷玩运动周周有安排。   ，同时在这里可以为你提供一些健康运动的建议，提高你的生活质量。运动官app是一款大众运动健康管理软件，通过用户自行输入身高、体重等个人信息为用户计算BMI健康数据，并给出健康建议；运动数据实时记录工具培养用户养成科学运动健康习惯的同时，还可以累计步数换取实物奖励。'
          }
        ],
        bigTextLastArr:[
          {
            imgUrl:require('../assets/img/u346.png')
  
          },
          {
            imgUrl:require('../assets/img/u347.png')
  
          },
          {
            imgUrl:require('../assets/img/u351.png')
  
          },
          {
            imgUrl:require('../assets/img/u349.png')
  
          },
        ]
      }
     },
     methods:{
      handleShadow(index){
          // if(!this.isClose){
          //   this.activeShow = index
          //   this.isClose = true
          // }
           this.activeShow = index
           this.isClose = true
      },
      handleClose(){
        this.isClose = false
      },
     }
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  font-family: 'BebaPuHui';
  .logo {
    width: 100%;
    height: 3.5rem;
    // height: 100%;
    position: relative;
    text-align: center;
    // background-size:contain;
    background: url("../assets/img/123.jpg") no-repeat;
    background-size: 100% 100%;
  }
  .banner-font {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);

    .font-english {
      font-weight: 600;
      font-size: 0.2rem;
      animation: fadenum 4s 1;
      -webkit-animation: fadenum 4s 1;
      -moz-animation: fadenum 4s 1;
    }
    .font-chinese {
      margin-top: 0.2rem;
      font-size: 0.12rem;
      animation: fadenum 4s 1;
      -webkit-animation: fadenum 4s 1;
      -moz-animation: fadenum 4s 1;
    }
  }
  .bg {
    padding-top: 0.4rem;
    width: 100%;
    // height: 2.1rem;
    background-color: #fff;
    display: flex;
    .bg-left {
      width: 2rem;
      // height: 100%;
      height: 1.9rem;
      text-align: center;
      font-size: 0.2rem;
      border-right: 2px solid #ccc;
      font-weight: 600;
    }
    .bg-right {
      flex: 1;
      text-align: left;
      margin-left: 0.5rem;
      .bg-right-one {
        font-size: 0.08rem;
        line-height: 28px;
      }
      .bg-right-two {
        font-size: 0.14rem;
        font-weight: 600;
        margin: 0.2rem 0;
      }
    }
  }
  .Culture {
    margin-top: 0.2rem;
    width: 100%;
    height: 4.5rem;
    background-color: #f8f8f8;
    overflow: hidden;
    .Culture-content {
      margin: 0.5rem;
      height: 3.5rem;
      // background-color: aqua;
      display: flex;
      align-items: center;
      .Culture-content-left {
        flex: 1;
        .Culture-content-left-title {
          font-size: 0.2rem;
          // font-weight: 600;
        }
        .Culture-content-left-one {
          font-size: 0.12rem;
          margin-top: 0.2rem;
        }
        .Culture-content-left-two {
          font-size: 0.08rem;
          // margin-top: 0.04rem;
          line-height: 28px;
        }
      }
      .Culture-content-right {
        flex: 1;
        .video {
          width: 4rem;
          height: 2rem;
          display: block;
        }
      }
    }
  }
  .route {
    width: 100%;
    // min-height: 4rem;
    background-color: #fff;
    overflow: hidden;
    .route-content {
      margin: 0.5rem;
      // height: 3.5rem;
      .route-content-title {
        font-size: 0.2rem;
      }
      .route-content-top {
        display: flex;
        text-align: left;
        // align-items: center;
        // height: 1rem;
        .route-content-top-one {
          flex: 1;
          .route-content-top-year {
            font-size: 0.12rem;
            margin-top: 0.4rem;
            display: flex;
            align-items: center;
            .qizi {
              background: url("../assets/img/qizi.jpg") no-repeat;
              background-size: cover;
              width: 0.12rem;
              height: 0.12rem;
              margin-left: 5px;
            }
            .xiaoren {
              background: url("../assets/img/xiaoren.jpg") no-repeat;
              background-size: cover;
              width: 0.12rem;
              height: 0.12rem;
              margin-left: 5px;
            }
          }
          .route-content-top-info {
            font-size: 0.08rem;
            margin: 0.1rem 0;
          }
          .route-content-top-english {
            font-size: 0.08rem;
            line-height: 24px;
          }
        }
      }
    }
  }
  .bigText{
    width: 100%;
    // height: 4.5rem;
    background-color: #f8f8f8;
    overflow: hidden;
    .bigText-content{
       margin: 0.5rem;
      // height:3.5rem;
      .bigText-content-title {
        font-size: 0.2rem;
      }
      .bigText-content-details, .bigText-content-details-next{
        margin-top: 0.4rem;
        // height:3.5rem;
        cursor: pointer;
        // background-color: paleturquoise;
        display: flex;
        .bigText-content-details-item{
          width: 1.5rem;
          height: 1.8rem;
          // background-color: antiquewhite;
          
          background-size: 100% 100% !important;
          position: relative;
          .bigText-content-details-shadow{
            width: 100%;
            height: 100%;
            position: absolute;
            left:0;
            top:0;
            background-color: rgba(2, 113, 164, 0.8);
            
            .shadow-date{
             font-size: 0.1rem;
             font-weight: 600;
             color:#ffff;
             padding-top: 0.4rem;
            }
            .shadow-company{
              letter-spacing: 1px;
              color:#ffff;
              font-size: 0.08rem;
              margin-top: 0.2rem;
            }
            .shadow-mark{
             letter-spacing: 1px;
             color:#ffff;
             font-size: 0.08rem;
             margin-top: 0.2rem;
            }
          }
          .flip-front {
        // transform: rotateX(90deg);
         height: 0;
         transition: height 1s;
         -webkit-transition: height 1s; /* Safari */
         -moz-transition: height 1s;
        // transform-origin:top  ;
        // animation: fadeRotateX 0.5s 1;
        // -webkit-animation: fadeRotateX 0.5s 1;
        // -moz-animation: fadeRotateX 0.5s 1;
          }
           .flip-back {
          // transform: rotateX(0);
        //  transform-origin:bottom;
        //  animation: fadeRotateXReverse 0.5s 1;
        // -webkit-animation: fadeRotateXReverse 0.5s 1;
        // -moz-animation: fadeRotateXReverse 0.5s 1;
         height: 1.8rem;
         transition: height 1s;
         -webkit-transition: height 1s; /* Safari */
         -moz-transition: height 1s;

    }
          .bigText-content-details-intro{
            position: absolute;
            width: 3rem;
            height: 100%;
            display: flex;
            left:1.5rem;
            align-items: center;
            // flex-direction: column;
            background-color: #111;
            z-index: 100;
            p{
              margin: 0 0.4rem;
              font-size: 0.07rem;
              color: #fff;
              line-height: 0.16rem;
                display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
    -webkit-line-clamp: 10; /* 2行，只有 webkit内核支持 */
    word-break: break-all; /* 纯英文换行 */
    overflow: hidden;
            }
            .bigText-content-details-intro-icon{
             color:#ffff;
             position:absolute;
             right:0.1rem;
             bottom: 0.1rem;
             font-size: 0.1rem;
            }
          }
        }
        .bigText-content-details-shadow:hover{
            -webkit-box-shadow: #e9e9eb 0px 2px 2px 2px;
            -moz-box-shadow: #e9e9eb 0px 2px 2px 2px;
            box-shadow: inset #e9e9eb 0px 2px 2px 2px;
        }
        .bigText-content-details-item-right{
          flex: 1;
          height: 1.8rem;
          background: url(../assets/img/u348.png);
          background-size: 100% 100%;
        }
      }
      .bigText-content-details-next{
        margin-top: 0;
        .bigText-content-details-next-left{
          width: 1.2rem;
          height: 1.8rem;
          background: url(../assets/img/u340.jpg);
          background-size: 100% 100%;
        }
        .bigText-content-details-next-right{
          flex: 1;
          height: 1.8rem;
           position: relative;
          .bigText-content-details-next-right-content{
            display: flex;
            div{
              flex: 1;
               height: 1.8rem;
         
          background-size: 100% 100% !important; 

            }
          }
          .bigText-content-details-next-right-shadow{
             width: 100%;
            height: 100%;
            position: absolute;
            left:0;
            top:0;
            background-color: rgba(1, 170, 242, 0.8);
            text-align: left;
            .shadow-date{
             font-size: 0.1rem;
             font-weight: 600;
             color:#ffff;
             margin-top: 0.32rem;
            }
            .shadow-company{
              letter-spacing: 1px;
              color:#ffff;
              font-size: 0.08rem;
              margin-top: 0.2rem;
            }
            .shadow-mark{
             letter-spacing: 1px;
             color:#ffff;
             font-size: 0.08rem;
             margin-top: 0.2rem;
            }
          }
              .flip-front {
        height: 0;
         transition: height 1s;
         -webkit-transition: height 1s; /* Safari */
         -moz-transition: height  1s;
         overflow:hidden
          }
           .flip-back {
        height: 1.8rem;
         transition: height 1s;
         -webkit-transition: height 1s; /* Safari */
         -moz-transition: height 1s;
          overflow:hidden
    }
  .bigText-content-details-next-right-shadow:hover{
            -webkit-box-shadow: #e9e9eb 0px 2px 2px 2px;
            -moz-box-shadow: #e9e9eb 0px 2px 2px 2px;
            box-shadow: inset #e9e9eb 0px 2px 2px 2px;
        }
          .bigText-content-details-intro{
            position: absolute;
            width: 4.8rem;
            height: 1.8rem;
            display: flex;
            top:0;
            right:0;
            align-items: center;
            // flex-direction: column;
            background-color: #111;
            z-index: 10;
            p{
              margin: 0 0.4rem;
              font-size: 0.07rem;
              color: #fff;
              line-height: 0.16rem;
               display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
    -webkit-line-clamp: 10; /* 2行，只有 webkit内核支持 */
    word-break: break-all; /* 纯英文换行 */
    overflow: hidden;
            }
            .bigText-content-details-intro-icon{
             color:#ffff;
             position:absolute;
             right:0.1rem;
             bottom: 0.1rem;
             font-size: 0.1rem;
            }
          }
        }
      }
    }
  }
  .mine {
    width: 100%;
    // height: 4.5rem;
    // background-color: #f8f8f8;
    overflow: hidden;
    .mine-content {
      margin: 0.5rem;
      // height:3.5rem;
      .mine-content-title {
        font-size: 0.2rem;
      }
      .mine-content-details {
        margin-top: 0.4rem;
        // text-indent: 2em;
        line-height: 28px;
        font-size: 0.08rem;
      }
      .mine-content-img {
        display: flex;
        margin-top: 0.4rem;
        .el-image {
          flex: 1 !important;
        }
      }
    }
  }
  .mine-content-adress {
    // width: 100%;
    margin: 0 0.06rem;
    // background-color: #f8f8f8;
    .el-image {
      width: 100%;
      display: block;
    }
  }
  .contact {
    // margin-top: 0.1rem;
    width: 100%;
    // height: 2rem;
    margin: 0.5rem 0 ;
    display: flex;
    // align-items: center;
    font-size: 0.08rem;
    line-height: 28px;

    .contact-title {
      font-size: 0.2rem;

      font-weight: 600;
    }
    .contact-content-top {
      display: block;
      margin-top: 0.4rem;
    }
    .contact-left {
      flex: 1;
      .contact-content-left {
        margin-left: 1.5rem;
      }
    }
    .contact-right {
      flex: 1;
      .contact-content-right {
        margin-left: 1.5rem;
      }
    }
  }
  .download {
    width: 100%;  
    // height: 4.5rem;
    background-color: rgba(34, 34, 34, 1);
    .download-content {
      display: flex;
      // height: 100%;
      .download-content-left {
        width: 3rem;
        // height: 100%;
        margin: 0.5rem 0;
        display: flex;
        align-items: center;
        background-color: rgba(34, 34, 34, 1);
        .download-content-left-info {
          width: 2rem;
          // height: 3.5rem;
          margin: 0 auto;
          background-color: rgba(34, 34, 34, 1);
          color: #fff;
          .iconlogo {
            display: flex;
            align-items: center;
            .iconlogoimg {
              width: 0.15rem;
              height: 0.15rem;
            }
            .iconlogotext {
              font-size: 0.11rem;
              color: #fff;
              margin-left: 0.04rem;
            }
          }
          .download-content-left-introduce {
            margin-top: 0.15rem;
            font-size: 0.07rem;
            line-height: 28px;
          }
          .download-content-left-mode {
            display: flex;
            justify-content: space-between;
            margin-top: 0.4rem;
            .download-content-left-mode-apple {
              width: 0.9rem;
              height: 0.25rem;
              background: url("../assets/img/u912.png") no-repeat center;
              background-size: 80% 80%;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #111;
              cursor: pointer;
            }
            .download-content-left-mode-android {
              width: 0.9rem;
              height: 0.25rem;
              background: url("../assets/img/u913.png") no-repeat center;
              background-size: 80% 80%;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #111;
              cursor: pointer;
            }
          }
        }
      }
      .download-content-right {
        position: relative;
        flex: 1;
        background: url("../assets/img/u89.png") no-repeat;
        background-size: 100% 100%;
        .download-content-right-mode {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #111;
          opacity: 0.5;
        }
      }
    }
  }
}
@keyframes fadenum {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeRotateX {
  0% {
    // transform: rotateX(0) ;
    height: 1.8rem !important;
  }
  100% {
    // transform: rotateX(90deg) ;
    height: 0 !important;
  }
}
@keyframes fadeRotateXReverse {
  0% {
    height: 0;
  }
  100% {
    height: 1.8rem;
  }
}
@media screen and (min-width: 960px) {
    .Adaptation{
      width:85% ;
      margin:0 auto;
      overflow: hidden;
    }
}
@media screen and (min-width: 1024px) {
    .Adaptation{
      width:90% ;
      margin:0 auto;
      overflow: hidden;
    }
}
@media screen and (min-width: 1280px) {
    .Adaptation{
      width:85% ;
      margin:0 auto;
      overflow: hidden;
    }
}
@media screen and (min-width: 1366px) {
    .Adaptation{
      width:80% ;
      margin:0 auto;
      overflow: hidden;
    }
}
@media screen and (min-width: 1440px) {
    .Adaptation{
      width:80% ;
      margin:0 auto;
      overflow: hidden;
    }
}
@media screen and (min-width: 1600px) {
    .Adaptation{
      width:80% ;
      margin:0 auto;
      overflow: hidden;
    }
}
@media screen and (min-width: 1680px) {
    .Adaptation{
      width:70% !important;
      margin:0 auto;
      overflow: hidden;
    }
}
</style>
